import React, { useState, useEffect } from "react";
import { List as AdminList } from "react-admin";
import PickupFilter from "../RouteEdit/PickupFilter";
import LocalShipping from "@material-ui/icons/LocalShipping";
import { guardListAccess } from "../../../util/Guard";
import { useLocationDiffDialog } from "./LocationDiffDialog";
import {
  Card,
  CardContent,
  Typography,
  Switch,
  FormControlLabel,
  List,
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import httpClient from "../../../util/HttpClient";
import { useMiscEditor } from "./MiscEditor";
import DeliveryActions from "./DeliveryActions";
import DeliveryListItem from "./DeliveryListItem";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((item, idx) => {
    item.index = idx;
  });
  return result;
};

const getListStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

const parseData = ({ ids, data }) => {
  let rows = [];
  let prods = {};
  ids.forEach((e) => {
    rows.push(data[e]);
    data[e].orderlines.forEach((ol) => {
      prods[ol.productName] =
        parseInt(prods[ol.productName] || 0) + parseInt(ol.quantity);
    });
  });
  return [rows, prods];
};

const Orderable = ({ loading, ids, data, filterValues, ...props }) => {
  let [items, setItems] = useState([]);
  let [editEnabled, setEditEnabled] = useState(false);
  let [loadList, setLoadList] = useState({});

  const itemChanged = () => {
    setItems([...items]);
  };

  let [miscEditor, misc] = useMiscEditor(items, itemChanged);
  let [locDialog, readyBtn] = useLocationDiffDialog(items, itemChanged);

  useEffect(() => {
    let [items, prods] = parseData({ ids, data });
    setItems(items);
    setLoadList(prods);
  }, [ids, data])


  if (loading) return <></>;

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);

    httpClient(`routeOrders/${filterValues.routeId}`, {
      method: "PUT",
      body: JSON.stringify(newItems.map((item) => item.id)),
    });
  };

  return (
    <>

      {miscEditor}
      {locDialog}
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Load list
          </Typography>
          <table>
            <tbody>
              {Object.keys(loadList).map((e, idx) => (
                <tr key={idx}>
                  <td>{e}</td>
                  <td>{loadList[e]}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <FormControlLabel
            control={
              <Switch
                value={editEnabled}
                onChange={() => setEditEnabled(!editEnabled)}
                label={"Order"}
              />
            }
            label="Enable reordering"
          />
        </CardContent>
      </Card>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <List style={getListStyle(snapshot.isDraggingOver)}>
                {items.map((item, index) => {
                  return (
                    <DeliveryListItem
                      isDragDisabled={!editEnabled}
                      {...{ item, index, misc, itemChanged, readyBtn, isDelivery: true }}
                    />
                  );
                })}
                {provided.placeholder}
              </List>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export const DeliveryList = (props) => {
  return (
    <AdminList
      bulkActionButtons={false}
      {...props}
      actions={<DeliveryActions />}
      pagination={<></>}
      filters={props.hideFilters ? null : <PickupFilter weekInput routeMode />}
    >
      <Orderable />
    </AdminList>
  );
};
const resource = ({ canRead }) => {
  return {
    name: "deliverylists",
    list: guardListAccess(DeliveryList),
    icon: LocalShipping,
    options: {
      label: "Delivery",
      group: "Account",
      showMenu: canRead("deliverylists"),
    },
  };
};
export default resource;
