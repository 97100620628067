import React from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import httpClient from "../../../util/HttpClient";
import Dialog from "@material-ui/core/Dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import CallIcon from "@material-ui/icons/Call";
import {
  FilledInput,
  Typography,
  IconButton,
  TextField,
  FormGroup,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

import InputIcon from "@material-ui/icons/Chat";
import { red, green } from "@material-ui/core/colors";

const useStyles = makeStyles({
  avatarGreen: {
    backgroundColor: green[100],
    color: green[600],
  },
  avatarRed: {
    backgroundColor: red[100],
    color: red[600],
  },
  input: {
    margin: 10,
  },
  phone: {
    width: "100%",
  },
});

function MiscDialog({ item: orgItem, onClose, from: orgFrom }) {
  const classes = useStyles();
  let [item, setItem] = useState(orgItem);
  let [from, setFrom] = useState(orgFrom);
  let [dialing, setDialing] = useState(false);
  if (orgItem.id !== item.id) {
    setItem(orgItem);
    setFrom(orgFrom);
    setDialing(false);
  }
  let setValue = (name, val) => {
    item[name] = val;
    setItem({ ...item });
    orgItem[name] = val;
  };
  const call = (props) => {
    setDialing(true);
    httpClient(`click2Dials/dial`, {
      method: "PUT",
      body: JSON.stringify(props),
    }).then((res) => {
      setTimeout(() => setDialing(false), 2500);
    });
  };

  let handleOnClose = () => {
    onClose(item);
  };
  return (
    <Dialog
      onClose={handleOnClose}
      aria-labelledby="simple-dialog-title"
      open={item.id ? true : false}
    >
      <DialogTitle id="simple-dialog-title">Opmerkingen</DialogTitle>
      <DialogContent>
        <FormGroup>
          <TextField
            value={item.acMisc}
            label="Bezorgtip"
            className={classes.input}
            onChange={(e) => setValue("acMisc", e.target.value)}
            multiline
            variant="outlined"
            rows={4}
          />
          <TextField
            value={item.orMisc}
            label="Opmerking"
            className={classes.input}
            onChange={(e) => setValue("orMisc", e.target.value)}
            multiline
            variant="outlined"
            rows={4}
          />
        </FormGroup>
        {item.phone ? (
          <div className={classes.input}>
            <Typography>
              Nummer van <b>je eigen</b> toestel
            </Typography>
            <FilledInput
              id="filled-adornment-password"
              value={from}
              variant="outlined"
              className={classes.phone}
              onChange={(e) => setFrom(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <Avatar
                    className={
                      dialing ? classes.avatarRed : classes.avatarGreen
                    }
                  >
                    <IconButton
                      onClick={() => call({ from, orderId: item.id })}
                    >
                      <CallIcon />
                    </IconButton>
                  </Avatar>
                </InputAdornment>
              }
            />
            <Typography>
              <b>Let op:</b> toestel opnemen! Daarna word je verbonden met de
              klant.
            </Typography>
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

export const useMiscEditor = (items, itemChanged) => {
  //9=eturnable
  let [item, setItem] = useState({});
  let [from, setFrom] = useState();

  const handleClickOpen = (item) => {
    httpClient("Click2Dials").then(({ json }) => {
      setFrom(json);
      setItem(item);
    });
  };

  const onClose = (item) => {
    itemChanged();
    httpClient(`deliverylists/${item.id}`, {
      method: "PUT",
      body: JSON.stringify(item),
    }).then(() => {
      setItem({});
    });
  };

  let dialog = (
    <MiscDialog
      onClose={onClose}
      item={item}
      itemChanged={itemChanged}
      from={from}
    />
  );
  let getButton = (item, handler) => (
    <div>
      <IconButton onClick={() => handleClickOpen(item)}>
        <InputIcon />
      </IconButton>
    </div>
  );

  return [dialog, getButton];
};
