import React from "react";
import { isIOS } from "react-device-detect";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import ExploreIcon from "@material-ui/icons/Explore";
import { Draggable } from "react-beautiful-dnd";
import moment from "moment"

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const name = ({ firstName, lastName, middleName, deliveryTime }) => {
  return `${lastName}, ${`${firstName || ""} ${
    middleName ? middleName.toLowerCase() : ""
    }`.trim()} ${deliveryTime ? new moment(deliveryTime).format("HH:mm") : ''}`;
};
const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
    fontSize: "0.875rem",
    marginLeft: 5,
  },
  bold: {
    fontWeight: "bold",
    display: "inline",
    fontSize: "0.875rem",
    marginLeft: 5,
  },
  secondAction: {
    display: 'flex'
  }
}));

const navigateClick = (item) => {
  var url = [
    isIOS ? "comgooglemaps://" : "http://maps.google.com/maps/",
    "?directionsmode=driving&",
    "daddr=",
    item.lat,
    ",",
    item.lng,
  ];

  window.location.replace(url.join(""));
};



const DeliveryListItem = ({
  item,
  index,
  itemChanged,
  r7sButton,
  misc,
  isDelivery,
  isDragDisabled,
  readyBtn
}) => {
  const classes = useStyles();
  return (
    <Draggable
      key={item.id}
      draggableId={`${item.id}`}
      isDragDisabled={isDragDisabled}
      index={index}
    >
      {(provided, snapshot) => (
        <ListItem
          ContainerComponent="li"
          ContainerProps={{ ref: provided.innerRef }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {readyBtn(item)}

          <ListItemText
            primary={`${index + 1}.${name(item)} ${
              item.crates && item.crates.length ? `-${item.crates}` : ""
              } `}
            secondary={
              <>
                {`${item.address}, ${item.city} ${item.note || ''} `}

                <Typography
                  component="span"
                  variant="body2"
                  color="secondary"
                >{`${item.acMisc || ""} ${item.orMisc || ""} `}</Typography>
                <br />
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {item.orderlines.map((ol) => {
                    let line = `${ol.quantity} x ${ol.productName} `;
                    return (
                      <Typography
                        className={
                          ol.quantity !== 1 ? classes.bold : classes.inline
                        }
                      >
                        {line} -
                      </Typography>
                    );
                  })}
                </Typography>
              </>
            }
          />

          <ListItemSecondaryAction className={classes.secondAction}>
            {misc(item)}
            {!item.deliveryTime && <IconButton
              onClick={() => {
                navigateClick(item);
              }}
            >
              <ExploreIcon />
            </IconButton>}

          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Draggable>
  );
};
export default DeliveryListItem;
